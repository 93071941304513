<template>
  <div class="company px-2 py-2">
    <va-breadcrumbs class="mb-3">
      <va-breadcrumbs-item label="部门管理" />
      <va-breadcrumbs-item label="添加部门" />
    </va-breadcrumbs>
    <va-form ref="recordForm" class="form" @validation="false">
      <div class="row">
        <div class="flex md6 xs12">
          <va-input class="" label="部门名称" v-model="record.name" :disabled="formReadonly" outline :rules="[value => (value && value.length > 0) || '必填']" />
        </div>
        <div class="flex md6 xs12">
        <va-select label="上级部门" outline clearable
            :options="parentDeparmentOptions" 
            @update-search="searchParentDeparment"
            v-model="record.parentDepartmentId"
            :text-by="option => option.key" :value-by="option => option.value"
            searchable no-options-text="暂无结果" placeholder="请输入部门名称查找" :loading="companyInSerach">
            <template #appendInner>
              <va-icon name="corporate_fare"/>
            </template>
          </va-select>
        </div>
        <div class="flex md6 xs12">
          <va-input label="电话号码" v-model="record.telephoneNo" :disabled="formReadonly"  outline />
        </div>
        <div class="flex md6 xs12">
          <va-input label="传真" v-model="record.fax" :disabled="formReadonly"  outline />
        </div>
        <div class="flex md12 xs12">
          <va-input class="" label="部门介绍" outline v-model="record.description" type="textarea" :min-rows="5" :max-rows="20"  :disabled="formReadonly" />
        </div>
      </div>
      <div class="command">
        <va-button :rounded="false" class="px-2" @click="submit" v-if="!formReadonly">提交</va-button>
        <va-button :rounded="false" class="ml-3 px-2" @click="returnToList" outline flat>返回</va-button>
      </div>
    </va-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, computed, ref, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import selectOptionService from '@/services/SelectOptionService'
import { SelectOption } from '@/services/SelectOptionService'
import { Company, Department } from '@/model'
import { AxiosResponse } from 'axios'

export default defineComponent({
  name: 'addOrEditDepartment',
  setup() {
    const recordForm = ref<any>(null)
    const route = useRoute()
    const router = useRouter()
    const app = getCurrentInstance()
    const record = reactive({} as Department)
    const formReadonly = computed(() => !!route.params.companyId && route.query.readonly == '1' )
    const originalDeparmentName = ref('')
    const selectOptions = reactive({
      industryTypes: [] as Array<SelectOption>,
      projectTypes: [] as Array<SelectOption>,
      projectSource: [] as Array<SelectOption>,
      projectStatus: [] as Array<SelectOption>,
      companyType: [] as Array<SelectOption>
    })

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

     /* target parent department */
    const parentDeparmentOptions = reactive([] as Array<SelectOption>)
    function searchParentDeparment(keyword: string) {
      if(!keyword){
        return
      }
      axiosService.get(`odata/departments?$top=20&$skip=0&$select=name,id&$filter=contains(name,'${keyword}')`).then((res: AxiosResponse<{ value: Array<{name: string, id: number}>}>) => {
        const parentDeparment = parentDeparmentOptions.find(c => c.value == record.id)
        parentDeparmentOptions.splice(0, parentDeparmentOptions.length)
        Array.prototype.push.apply(parentDeparmentOptions, res.data.value.map(dept => {
          return {
            key: dept.name,
            value: dept.id
          }
        }))

        if(parentDeparment && !parentDeparmentOptions.find(c => c.value == parentDeparment.value)) {
          parentDeparmentOptions.push(parentDeparment)
        }

        if(!parentDeparmentOptions.some(d => d.value == record.parentDepartmentId))
        {
          parentDeparmentOptions.push({key: record.parentDepartment?.name ?? '', value: record.parentDepartmentId ?? ''} as SelectOption)
        }
      })
    }


    function submit() {
      if (recordForm.value.validate()) {
        if(!record.id)
        {
          axiosService.get(`odata/departments?$filter=name eq '${record.name}'`)
            .then(res => {
              if (res.data.value.length > 0) {
                toast({
                  message: `${record.name}已存在，不能重复添加`,
                  color: 'danger',
                  closeable: true,
                  duration: 2000
                })
                return Promise.reject()
              }
            })
            .then(() => {
              return axiosService.post('api/departments', record)
            })
            .then(res => {
              toast({
                message: '添加部门成功！',
                color: 'success',
                closeable: true,
                duration: 2000
              })
              router.push({ path: '/permission/departments' })
            })
        }
        else
        {
          axiosService.get(`odata/departments/?$filter=name eq '${record.name}' and name ne '${originalDeparmentName.value}'`)
            .then(res => {
              if (res.data.value.length > 0) {
                toast({
                  message: `${record.name}已存在，请确认后重新提交！`,
                  color: 'danger',
                  closeable: true,
                  duration: 2000
                })
                return Promise.reject()
              }
            })
            .then(() => {
              if(record.parentDepartment && record.parentDepartmentId){
                record.parentDepartment.id = record.parentDepartmentId
              }
              return axiosService.patch(`api/departments/${record.id}`, record)
            })
            .then(res => {
              toast({
                message: '修改部门信息成功！',
                color: 'success',
                closeable: true,
                duration: 2000
              })
              router.push({ path: '/permission/departments' })
            })
        }
      }
    }

    function returnToList()
    {
      router.push('/permission/departments')
    }

    onMounted(async () => {
      await selectOptionService.waitOptionsLoadComplete()
      selectOptions.industryTypes = selectOptionService.optionCollection.get('industry_type') || []
      selectOptions.projectStatus = selectOptionService.optionCollection.get('ProjectStatus') || []
      selectOptions.projectSource = selectOptionService.optionCollection.get('CompanySource') || []
      selectOptions.companyType = selectOptionService.optionCollection.get('CompanyType') || []
      selectOptions.projectTypes = selectOptionService.optionCollection.get('ImportantEnterpriseType') || []

      if (route.params.deparmentId) {
        axiosService.get(`odata/departments(${route.params.deparmentId})?$expand=parentDepartment($select=name,id)`).then(res => {
          if (res.data) {
            record.id = res.data.id
            record.name = res.data.name
            record.description = res.data.description
            record.parentDepartment = res.data.parentDepartment
            record.parentDepartmentId = res.data.parentDepartmentId
            originalDeparmentName.value = res.data.name
            parentDeparmentOptions.push({key: res.data.parentDepartment.name, value: res.data.parentDepartmentId} as SelectOption)
          }
        })
      }
    })

    return {
      record,
      selectOptions,
      recordForm,
      formReadonly,
      submit,
      returnToList,
      parentDeparmentOptions,
      searchParentDeparment,
    }
  }
})
</script>

<style lang="scss" scoped>
.company {
  min-height: 100%;
  background-color: #fff;
  .command
  {
    margin-top: 40px;
  }
}
</style>
