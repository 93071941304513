
import { defineComponent, reactive, onMounted, computed, ref, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import selectOptionService from '@/services/SelectOptionService'
import { SelectOption } from '@/services/SelectOptionService'
import { Company, Department } from '@/model'
import { AxiosResponse } from 'axios'

export default defineComponent({
  name: 'addOrEditDepartment',
  setup() {
    const recordForm = ref<any>(null)
    const route = useRoute()
    const router = useRouter()
    const app = getCurrentInstance()
    const record = reactive({} as Department)
    const formReadonly = computed(() => !!route.params.companyId && route.query.readonly == '1' )
    const originalDeparmentName = ref('')
    const selectOptions = reactive({
      industryTypes: [] as Array<SelectOption>,
      projectTypes: [] as Array<SelectOption>,
      projectSource: [] as Array<SelectOption>,
      projectStatus: [] as Array<SelectOption>,
      companyType: [] as Array<SelectOption>
    })

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

     /* target parent department */
    const parentDeparmentOptions = reactive([] as Array<SelectOption>)
    function searchParentDeparment(keyword: string) {
      if(!keyword){
        return
      }
      axiosService.get(`odata/departments?$top=20&$skip=0&$select=name,id&$filter=contains(name,'${keyword}')`).then((res: AxiosResponse<{ value: Array<{name: string, id: number}>}>) => {
        const parentDeparment = parentDeparmentOptions.find(c => c.value == record.id)
        parentDeparmentOptions.splice(0, parentDeparmentOptions.length)
        Array.prototype.push.apply(parentDeparmentOptions, res.data.value.map(dept => {
          return {
            key: dept.name,
            value: dept.id
          }
        }))

        if(parentDeparment && !parentDeparmentOptions.find(c => c.value == parentDeparment.value)) {
          parentDeparmentOptions.push(parentDeparment)
        }

        if(!parentDeparmentOptions.some(d => d.value == record.parentDepartmentId))
        {
          parentDeparmentOptions.push({key: record.parentDepartment?.name ?? '', value: record.parentDepartmentId ?? ''} as SelectOption)
        }
      })
    }


    function submit() {
      if (recordForm.value.validate()) {
        if(!record.id)
        {
          axiosService.get(`odata/departments?$filter=name eq '${record.name}'`)
            .then(res => {
              if (res.data.value.length > 0) {
                toast({
                  message: `${record.name}已存在，不能重复添加`,
                  color: 'danger',
                  closeable: true,
                  duration: 2000
                })
                return Promise.reject()
              }
            })
            .then(() => {
              return axiosService.post('api/departments', record)
            })
            .then(res => {
              toast({
                message: '添加部门成功！',
                color: 'success',
                closeable: true,
                duration: 2000
              })
              router.push({ path: '/permission/departments' })
            })
        }
        else
        {
          axiosService.get(`odata/departments/?$filter=name eq '${record.name}' and name ne '${originalDeparmentName.value}'`)
            .then(res => {
              if (res.data.value.length > 0) {
                toast({
                  message: `${record.name}已存在，请确认后重新提交！`,
                  color: 'danger',
                  closeable: true,
                  duration: 2000
                })
                return Promise.reject()
              }
            })
            .then(() => {
              if(record.parentDepartment && record.parentDepartmentId){
                record.parentDepartment.id = record.parentDepartmentId
              }
              return axiosService.patch(`api/departments/${record.id}`, record)
            })
            .then(res => {
              toast({
                message: '修改部门信息成功！',
                color: 'success',
                closeable: true,
                duration: 2000
              })
              router.push({ path: '/permission/departments' })
            })
        }
      }
    }

    function returnToList()
    {
      router.push('/permission/departments')
    }

    onMounted(async () => {
      await selectOptionService.waitOptionsLoadComplete()
      selectOptions.industryTypes = selectOptionService.optionCollection.get('industry_type') || []
      selectOptions.projectStatus = selectOptionService.optionCollection.get('ProjectStatus') || []
      selectOptions.projectSource = selectOptionService.optionCollection.get('CompanySource') || []
      selectOptions.companyType = selectOptionService.optionCollection.get('CompanyType') || []
      selectOptions.projectTypes = selectOptionService.optionCollection.get('ImportantEnterpriseType') || []

      if (route.params.deparmentId) {
        axiosService.get(`odata/departments(${route.params.deparmentId})?$expand=parentDepartment($select=name,id)`).then(res => {
          if (res.data) {
            record.id = res.data.id
            record.name = res.data.name
            record.description = res.data.description
            record.parentDepartment = res.data.parentDepartment
            record.parentDepartmentId = res.data.parentDepartmentId
            originalDeparmentName.value = res.data.name
            parentDeparmentOptions.push({key: res.data.parentDepartment.name, value: res.data.parentDepartmentId} as SelectOption)
          }
        })
      }
    })

    return {
      record,
      selectOptions,
      recordForm,
      formReadonly,
      submit,
      returnToList,
      parentDeparmentOptions,
      searchParentDeparment,
    }
  }
})
